<template>
  <v-col
    cols="12"
    lg="2"
    md="3"
    class="sticky-sidebar"
    :class="[$vuetify.breakpoint.mobile ? 'pb-4 pt-4 px-0' : 'pt-8']"
  >
    <div class="flex flex-column align-start" style="height: 100%">
      <v-list
        v-if="!$vuetify.breakpoint.mobile"
        class="pt-0 sticky-sidebar__item"
      >
        <v-btn
          elevation="0"
          class="mt-0 pt-0"
          id="#switch"
          ref="switch"
          outlined
          color="gray"
          large
          block
          @click="$emit('goBack')"
          style="max-width: 300px !important"
        >
          <v-icon size="16px" color="gray--darken-1"> $arrowleft </v-icon>
          {{ $t('button.dashboard.back') }}
        </v-btn>

        <v-list-item-group
          v-model="selectedGroup"
          color="primary"
          class="d-flex flex-row flex-md-column justify-start"
          :class="$vuetify.breakpoint.mobile ? 'mb-0' : ''"
        >
          <v-list-item
              v-for="(item, index)  in defaultThemeCategories"
              :key="`${item.value}-${index}`"
              :value="item"
              :active-class="'elevation-4--before'"
              :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


<!--          <v-list-item-->
<!--            value="popular"-->
<!--            :active-class="'elevation-4&#45;&#45;before'"-->
<!--            :ripple="false"-->
<!--          >-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Most Popular</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item-->
<!--              value="e-commerce"-->
<!--              :active-class="'elevation-4&#45;&#45;before'"-->
<!--              :ripple="false"-->
<!--          >-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>E-commerce</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item-->
<!--              value="new"-->
<!--              :active-class="'elevation-4&#45;&#45;before'"-->
<!--              :ripple="false"-->
<!--          >-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Latest</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
          <v-divider v-if="recommendedThemes"></v-divider>

          <v-list-item
              v-if="recommendedThemes"
              :value="recommendedCategory"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t('general.recommended') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-for="(item, index) in groups"
            :key="index"
            :value="item"
            :active-class="item.type !== 'divider' ? 'elevation-4--before' : ''"
            :disabled="item.type === 'divider'"
            :ripple="false"
          >

            <v-list-item-content>
              <v-divider v-if="item.type === 'divider'"></v-divider>
              <v-list-item-title  v-else
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-tabs
        prev-icon="$chevronleft"
        next-icon="$chevronright"
        center-active
        v-else
      >
        <v-tab
          v-for="(item, index)  in defaultThemeCategories"
          :key="`${item.value}-${index}`"
          :active-class="'elevation-4--before'"
          :ripple="false"
          class="p-5 px-3"
          @click="selectedGroup = item"
        >{{ item.text }}
        </v-tab>
        <v-tab
          v-for="item in groups"
          :key="item.id"
          :active-class="'elevation-4--before'"
          :ripple="false"
          class="p-5 px-3"
          @click="selectedGroup = item"
        >
          <v-divider v-if="item.type === 'divider'"></v-divider>
          <span v-else>{{ item.name }}</span>
        </v-tab>
      </v-tabs>
    </div>
  </v-col>
</template>

<script>
export default {
  computed: {
    selectedGroup: {
      get: function () {
        return this.group;
      },
      set: function (newValue) {
        if (newValue) {
          this.$emit("update:group", newValue);
        }
      },
    },
  },
  props: {
    groups: Array,
    loadingTags: Boolean,
    group: Object,
    allThemesCount: Number,
    defaultThemeCategories: Array,
    recommendedThemes: Boolean
  },
  data() {
    return {
      recommendedCategory: {
        text: this.$t('general.recommended'),
        value: "recommended",
        type: "recommended",
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.v-subheader {
  align-content: flex-start;
  height: 30px;
}
.v-item-group {
  margin-bottom: 24px;
}

.v-list {
  .v-list-item {
    padding-left: 0px;
    transition: padding-left 0.3s ease;
    @media (max-width: 975px) {
      box-shadow: none !important;
    }
    .v-list-item__content {
      position: relative;
      z-index: 1;
    }
    .v-list-item__title {
      font-weight: $font-weight-semibold;
      color: map-get($text, header);
      transition: color 0.24s ease, font-weight 0.24s ease;
    }
    &::before {
      z-index: 0;
      background: #fff;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &:hover {
      padding-left: 12px;
      .v-list-item__title {
        color: map-get($primary, base);
      }
    }
    &--active {
      padding-left: 12px;
      border-radius: 4px;
      .v-list-item__title {
        font-weight: $font-weight-bold;
        color: map-get($primary, darken1);
      }
      &::before {
        border-radius: 4px;
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: 975px) {
    .v-list-item-group {
      justify-content: flex-start;
    }
    .v-list-item {
      padding: 0 12px 0 12px !important;
      text-align: center;
      min-width: unset !important;
    }
  }
}

.sticky-sidebar {
  position: relative;
}

.sticky-sidebar__item {
  position: sticky;
  padding-left: 0px !important;
  top: 24px;
  left: 0;
}

.v-tabs::v-deep {
  padding: 8px 0 !important;
  .v-item-group {
    background-color: transparent;
  }
  .v-slide-group__wrapper {
    contain: none !important;
    overflow: visible !important;

    max-width: 100%;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-tab {
    position: relative;
    overflow: visible !important;
    padding: 0 16px;
    border-radius: 4px;
    box-shadow: none;
    transition: opacity 0.3s ease;
    margin-left: 4px;
    margin-right: 4px;
    background: transparent;
    span {
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: map-get($text, heading);
      font-weight: $font-weight-semibold;
    }
    &:not(:first-child) {
      margin-left: 0 !important;
    }

    &--active {
      background: #fff;

      span {
        color: map-get($primary, darken1);
        font-weight: $font-weight-bold;
      }
    }
  }
}
.v-application--is-rtl{
    .v-list {
      .v-list-item {
        padding-right: 0px;
        transition: padding-right 0.3s ease;
        &:hover{
            padding-right: 12px;
        }
        &--active{
            padding-right: 12px;
        }
      }
    }
}
</style>