<template>
  <v-menu
    :close-on-content-click="false"
    open-on-hover
    bottom
    :right="left ? undefined : true"
    :left="left ? true : undefined"
    :nudge-right="left ? '-12px' : '12px'"
    close-delay="150"
    open-delay="150"
    offset-x
    min-width="500px"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center">
        <loader
          v-on="on"
          v-bind="attrs"
          v-if="loading"
          :noMargins="true"
          :size="16"
          color="primary"
        />
        <v-icon
          v-else
          v-on="on"
          v-bind="attrs"
          size="16"
          style="line-height: 0px"
          :color="icon.color"
        >
          {{ `$${icon.type}` }}
        </v-icon>
      </div>
    </template>
    <div class="pa-5" v-if="!resolvedCorrectly">
      <div class="d-flex align-center flex-nowrap">
        <span class="p-3">
          {{ $t("heading.hosting.summary.nameserverCard.dnsRecords.text") }}
        </span>
        <default-button
          class="ml-auto"
          :outlined="true"
          small
          @click="resolveDnsRecord"
          :loading="loading"
          :disabled="btnDisabled"
        >
          <span class="gray--text">{{
            $t("tooltip.dnsIndicator.refreshNow")
          }}</span>
        </default-button>
      </div>

      <div class="list-container mt-4" v-if="dnsRecords.length">
        <span class="list-container__title">{{
          $t("tooltip.dnsIndicator.dnsRecords")
        }}</span>

        <ul class="nameserver-list nameserver-list--error">
          <li class="list-item" v-for="item in dnsRecords" :key="item">
            <span class="text-truncate">{{ item }}</span>
            <div class="ml-auto">
              <copy-text-button :text="item" />
            </div>
          </li>
        </ul>
      </div>

      <div
        class="list-container"
        v-if="instance.plan_settings.dns_nameservers_notification_enabled && nameservers && nameservers.length"
      >
        <span class="list-container__title">{{
          $t("tooltip.dnsIndicator.orNameservers")
        }}</span>

        <ul class="nameserver-list nameserver-list--info">
          <li class="list-item" v-for="item in nameservers" :key="item">
            <span class="text-truncate">{{ item }}</span>
            <div class="ml-auto">
              <copy-text-button :text="item" />
            </div>
          </li>
        </ul>
        <div class="ml-3">
          <strong>{{
            $t(
              "heading.instance.modal.setDomain.config.tab.nameservers.list.note_label"
            )
          }}</strong>
          {{
            $t(
              "heading.instance.modal.setDomain.config.tab.nameservers.list.note_text"
            )
          }}
        </div>
      </div>
    </div>
    <div class="pa-5" v-else>
      {{ sslInfo.text }}
    </div>
  </v-menu>
</template>

<script>
import Api from "@/apis/Api";
import CopyTextButton from "@/components/buttons/CopyTextButton.vue";
import DefaultButton from "@/components/DefaultButton.vue";
import Loader from "../Loader.vue";

export default {
  components: {
    CopyTextButton,
    DefaultButton,
    Loader,
  },
  props: {
    instance: Object,
    hasText: Boolean,
    left: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      loading: true,
      resolvedCorrectly: true,
      btnDisabled: false,
      ssl: {
        certificate_installed: false,
        certificate: null,
      },
      throttleTime: 5000,
    };
  },
  computed: {
    dnsRecords() {
      return [
        `${this.instance.domain} A ${this.instance.host_ip_address}`,
        `www.${this.instance.domain} CNAME ${this.instance.domain}`,
      ];
    },
    nameservers() {
      return this.instance.dns_nameservers;
    },
    sslInfo() {
      if (this.loading) {
        return {
          text: this.$t("tooltip.dnsIndicator.fetching", {
            url: this.instance.url,
          }),
        };
      }
      if (!this.ssl.certificate_installed) {
        return { text: this.$t("tooltip.dnsIndicator.notSsl") };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return {
          text: this.$t("tooltip.dnsIndicator.selfSigned"),
        };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return {
          text: this.$t("tooltip.dnsIndicator.sslNotMatched"),
        };
      }
      return { text: this.$t("tooltip.dnsIndicator.secure") };
    },
    icon() {
      if (!this.resolvedCorrectly) {
        return { type: "alertcircle", color: "warning" };
      }
      if (!this.ssl.certificate_installed) {
        return { type: "unlock", color: "error" };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return { type: "lock", color: "error" };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return { type: "unlock", color: "error" };
      }
      return { type: "lock", color: "success" };
    },
  },
  mounted() {
    if (!this.instance.host_ip_address) {
      this.loading = false;
      this.resolvedCorrectly = false;
      return;
    }
    this.getInfo();
  },
  methods: {
    async getInfo() {
      if (!this.instance.isActive) return;

      await this.getDetectedSslCertificate();
      if (this.ssl.certificate_installed === false) {
        await this.resolveDnsRecord();
      }
      this.loading = false;
    },
    async getDetectedSslCertificate() {
      await Api.hosting(this.instance.hostingId)
        .getDetectedCertificate(this.instance.domain)
        .then((response) => {
          if (response){
            this.ssl.certificate_installed = !!response;
            this.ssl.certificate = response;
          }
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
    async resolveDnsRecord() {
      await Api.dns()
        .resolveARecord(this.instance.domain)
        .then((result) => {
          if (!result) {
            this.resolvedCorrectly = false;
          }
        })
    },
    // resolveDnsRecord() {
    //   this.loading = true;
    //   Api.dns()
    //     .resolveARecord(this.instance.domain)
    //     .then((result) => {
    //       if (!result) {
    //         this.resolvedCorrectly = false;
    //       }
    //     })
    //     .finally(() => {
    //       setTimeout(async () => {
    //         if (this.resolvedCorrectly) {
    //           await this.checkSsl();
    //         }
    //         this.loading = false;
    //         this.btnDisabled = true;
    //         setTimeout(() => {
    //           this.btnDisabled = false;
    //         }, this.throttleTime);
    //       }, 500);
    //     });
    // },
    // async checkSsl() {
    //   if (!this.instance.isActive) {
    //     return;
    //   }
    //
    //   await Api.hosting(this.instance.hostingId)
    //     .getInstalledCertificate(this.instance.domain)
    //     .then((response) => {
    //       this.ssl.certificate_installed = response ? true : false;
    //       this.ssl.certificate = response;
    //     })
    //     .catch((error) => {
    //       this.$store.dispatch("addAlert", {
    //         success: false,
    //         errorMessage: Api.getErrorMessage(error),
    //       });
    //     });
    //
    //   if (
    //     !this.ssl.certificate ||
    //     (this.ssl.certificate && this.ssl.certificate.self_signed)
    //   ) {
    //     await Api.hosting(this.instance.hostingId)
    //       .getDetectedCertificate(this.instance.domain)
    //       .then((response) => {
    //         this.ssl.certificate_installed = !!response;
    //         this.ssl.certificate = response;
    //       })
    //       .catch((error) => {
    //         this.$store.dispatch("addAlert", {
    //           success: false,
    //           errorMessage: Api.getErrorMessage(error),
    //         });
    //       });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.dns-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--v-gray-lighten3);
    top: calc(50% - 7px);
    left: calc(50% - 7px);
    border: 3px solid var(--v-gray-lighten5);
  }

  &--online {
    &:before {
      background-color: var(--v-primary-base);
      border: 3px solid var(--v-primary-lighten5);
    }
  }

  &--offline {
    &:before {
      background-color: var(--v-error-base);
      border: 3px solid var(--v-error-lighten5);
    }
  }
}
.nameserver-list {
  padding: 0px;
  list-style: none;

  .list-item {
    position: relative;
    padding-left: 14px;
    font-weight: 400;
    font-size: var(--font-size-medium);
    display: flex;
    line-height: 25px;
    color: var(--v-gray-darken4);

    + .list-item {
      margin-top: 4px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      left: 0px;
      top: calc(50% - 3px);
      background: var(--v-primary-base);
    }
  }

  &--error {
    .list-item {
      &::before {
        background: var(--v-error-base);
      }
    }
  }
}

.list-container {
  + .list-container {
    margin-top: 16px;
  }

  &__title {
    display: inline-block;
    font-weight: 700;
    line-height: 26px;
    font-size: var(--font-size-base);
    color: var(--v-gray-darken4);
    margin-bottom: 8px;
  }
}
</style>
